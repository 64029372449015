<template>
  <SampleEditImages />
</template>

<script>
import SampleEditImages from "@/components/newSamples/SampleEditImages";
export default {
  name: "Images",
  components: { SampleEditImages }
};
</script>
