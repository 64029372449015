<template>
  <Page title="Sample Images">
    <v-container>
      <SampleImages :sample-id="$route.params.sampleId" />
      <v-row class="pa-5">
        <v-btn color="secondary" to="/samples/newbrowse">
          Return to Samples
        </v-btn>
      </v-row>
    </v-container>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import SampleImages from "@/components/newSamples/components/SampleImages";

export default {
  name: "SampleEditImages",
  components: { SampleImages, Page }
};
</script>
